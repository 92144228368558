<template>
    <div class="show-img">
        <img :src="this.params.data.urlImage" class="round w-full img-checkin" v-if="this.params.data.imageUrl"/>
        <div class="w-full" v-else>
            <card-customer :data="this.params.data" style="width:80px; height:80px"/>
        </div>
    </div>
</template>
<script>
import CardCustomer from './CardCustomer.vue'
export default {
    name: 'CellRendererActions',
    methods: {},
    components: {
        CardCustomer
    }
}
</script>
<style scoped>

.show-img .img-checkin {
    display: block;
    height: 95px;
    padding: 5px;
    width: 95px !important;
    object-fit: cover;
    object-position: center;
}

.show-img {
    position: relative
}

</style>
