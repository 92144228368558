<template>
    <div class="vx-card card">
      <img :src="data.imageUrl ? data.urlImage : urlImage" class="avatar avatar-status cursor-pointer" style="height:50px;width:50px">
    </div>
</template>

<script>
export default {
    props: {
        data: {},
        isEdit: false,
        disableCard: false
    },
    data(){
        return {
            isDelete: false,
            urlImage: require('@/assets/images/card/businessman.jpeg'),
        }
    },
    methods: {
        updateCardCustomer: function(){
          let id = this.data.cardNumber ? this.data.cardNumber : this.data.cardStaffId;
          if (!id) id = this.data.cardNumber;
            if (this.isEdit) {
                let path = `/user/card-customer-info?id=${id}`
                this.$router.push(path)
            }
        },
        confirmDelete(){
          this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: this.$t('warningMenuOrganization.titleWarning'),
              text: 'Bạn có muốn xoá thẻ từ không?',
              accept: this.deleteCard,
              acceptText: this.$t('button.delete'),
              cancelText: this.$t('button.cancel')
          })
        },
        confirmDisableCard(){
          this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: this.$t('warningMenuOrganization.titleWarning'),
              text: 'Huỷ áp dụng thẻ cho nhân sự hoặc khách hàng?',
              accept: this.disableCardCustomer,
              acceptText: this.$t('button.delete'),
              cancelText: this.$t('button.cancel')
          })
        },
        disableCardCustomer: function(){
          let id = this.data.cardNumber ? this.data.cardNumber : this.data.cardStaffId;
          this.$vs.loading();
          this.$crm.post(`/card-staff/cancel-card/${id}`).then(() => {this.$vs.loading.close(); this.$store.commit('DELETE_CARD', true);}).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                title: 'Error',
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
          })
        },
        deleteCard: function(){
          let id = this.data.cardNumber ? this.data.cardNumber : this.data.cardStaffId;
          this.$vs.loading();
          this.$crm.post(`/card-staff/delete/${id}`).then(() => {
            this.$store.commit('DELETE_CARD', true);
            this.$vs.loading.close();
          }).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                title: 'Error',
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
          })
        }
    },
    created(){
      console.log(this.data);
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat");
img {
  width: 100%;
}

.card {
  background-image: url('../../../../assets/images/card/bg-card.png');
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 24px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.avatar {
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  margin-bottom: 16px;
  padding: 4px;
  object-fit: contain;
  object-position: center;
}
.avatar-status {
    border: 2px solid #04d361;
    animation: borderHighlight 2s ease infinite;
}
.avatar-unused {
    filter: brightness(0.9);
}

@keyframes borderHighlight {
  50% {
    border-color: transparent;
  }
}

.title {
  font-size: 1.5rem;
  color: black;
  font-weight: 200;
}

.title span {
  font-weight: 600;
}

.circle {
  --size: 8rem;
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: #04d361;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.5;
  backdrop-filter: blur(4px);
}
</style>