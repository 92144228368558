<template>
    <div class="vx-col md:w-full sm:w-auto" v-if="this.params.data.faceType == 'unknown'"  >
        <vx-tooltip :text="$t('cardCustomer.addCustomer')" position="top">
            <feather-icon icon="UserPlusIcon" class="action-success mr-4"
                    svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                    @click="addCustomer"/>
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        addCustomer() {
            this.$router.push(`/user/add-customer?imageUrl=${this.params.data.imageUrl}`).catch(() => {
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('warningMenuCustomer.titleWarning'),
                text:  this.$t('warningMenuCustomer.textDeleteCustomer') + `"${this.params.data.fullName}"?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.$vs.loading();
            this.$crm.post(`/customer/delete/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                this.showDeleteSuccess();
                this.$store.commit('UPDATE_VIP_CUSTOMER', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: this.$t('warningMenuCustomer.deleteSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>
<style scoped>
.line-height {
    /* line-height: 100px; */
}
</style>
