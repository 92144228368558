<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="vx-col md:w-full flex items-center mb-5">
                <TitleScreen></TitleScreen>
            </div>
            <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full">
                    <label class="vs-input--label">{{ $t('minioStorage.dateRange') }}</label>
                    <date-range-picker ref="picker" :locale-data="{format: 'dd/mm/yyyy' }" :opens="'right'"
                        v-model="dateRange" class="w-full">
                    </date-range-picker>
                </div>
                <div class="vx-col md:w-1/4 w-full">
                    <label class="vs-input--label">{{ $t('cardCustomer.customerType') }}</label>
                    <v-select v-model="customerTypeModel" :options="customerTypes"></v-select>
                </div>
                <div class="vx-col md:w-1/4 sm:w-auto" style="display: inline-block">
                    <div v-if="this.customer.faceType != customerTypes[2].value">
                        <label class="vs-input--label">{{$t('menuCustomer.customer')}}</label>
                        <v-select class="" v-model="selectedCustomer" :clearable="false" :options="customerOptions">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                    </div>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-6">
                    <vs-input class="w-full" v-model="searchQuery" @input="updateSearchQuery"
                        :placeholder="$t('button.search')" />
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-5">
                <vs-button icon="icon-search" icon-pack="feather" @click="filter">{{ $t('cardCustomer.search') }}</vs-button>
            </div>
            <!-- AgGrid Table -->
            <div class="w-full customer-table">
                <ag-grid-vue style="height: 55vh"
                    ref="agGridTable"
                    :components="components"
                    :gridOptions="gridOptions"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef"
                    :rowData="customerData"
                    :animateRows="true" :pagination="true"
                    :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                    :paginationPageSize="paginationPageSize"
                    :onGridReady="onGridReady"
                    :suppressPaginationPanel="true">
                </ag-grid-vue>
            </div>

            <!-- ITEMS PER PAGE -->
            <Pagination :itemList="customerData" :currentPage="currentPage" :paginationPageSize="paginationPageSize"
                :gridApi="gridApi" />

            <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />

        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import authConfig from '@/../auth_config.json';
import Datepicker from 'vuejs-datepicker';
import CellRendererImages from "./cell-renderer/CellRendererImages.vue";
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import Pagination from "../../ui-elements/Pagination.vue"
import moment from 'moment'
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        AgGridVue,
        vSelect,
        Datepicker,
        CellRendererImages,
        CellRendererActions,
        Pagination,
        moment,
        DateRangePicker,
        TitleScreen
    },
    data() {
        return {
            customerTypes: [
                { value: "customer", label: this.$t('cardCustomer.customer'), valueNumber: 0 },
                { value: "guest", label: this.$t('cardCustomer.passers'), valueNumber: 1 },
                { value: "unknown", label: this.$t('cardCustomer.unknown'), valueNumber: 2 },
            ],
            customer: {
                start: null,
                end: null,
                faceType: 'customer',
                faceTypeValue: 1,
                // employeeId: []
            },
            showImg: false,
            imageUrl: "",
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {
                rowHeight: 100
            },
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('menuCustomer.customerCode'),
                    field: 'employeeId',
                    width: 140
                },
                {
                    headerName: this.$t('menuCustomer.customerName'),
                    field: 'fullName',
                    width: 160
                },
                {
                    headerName: this.$t('menuCamera.device'),
                    field: 'deviceType',
                    width: 100
                },
                {
                    headerName: this.$t('menuCustomer.img'),
                    field: 'urlImage',
                    width: 140,
                    cellRendererFramework: 'CellRendererImages'
                },
                {
                    headerName: this.$t('menuCustomer.timeOfRecord'),
                    field: 'checkinTime',
                    width: 130,
                },
                {
                    headerName: this.$t('menuCustomer.actions'),
                    width: 130,
                    cellRendererFramework: 'CellRendererActions'
                }
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions
            },
            customers: [],
            customerData: [],
            customerOptions: [
                { value: null, label: this.$t('menuCustomer.all') }
            ],
            selectedCustomerValue: null,
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setHours(0, 0, 0);
                    return date
                })()
            },
        }
    },
    async created() {
        this.customer.faceType = this.$route.query.type ? this.customerTypes.find(x => x.valueNumber == this.$route.query.type).value : 'customer';
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.customer.organizationId = userInfo.organization.id
        await this.getCustomer();
        this.selectedCustomerValue = this.$route.query.id ? Number.parseFloat(this.$route.query.id) : null;

        this.filter();
        this.$eventBus.$on('i18n', (event) => {
            this.columnDefs[0].headerName = this.$t('menuCustomer.customerCode');
            this.columnDefs[1].headerName = this.$t('menuCustomer.customerName');
            this.columnDefs[2].headerName = this.$t('menuCustomer.img');
            this.columnDefs[3].headerName = this.$t('menuCustomer.timeOfRecord');
            this.gridOptions.api.setColumnDefs(this.columnDefs);

        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateEmployee'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_EMPLOYEE', false);
            }
        }
    },
    computed: {
        customerTypeModel: {
            get() {
                let object = this.customerTypes.find(el => el.value == this.customer.faceType);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.selectedCustomerValue = null;
                this.customer.faceType = object ? object.value : null;
                this.getCustomer();
            }
        },
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedCustomer: {
            get() {
                return {
                    label: this.employeeString(this.selectedCustomerValue),
                    value: this.selectedCustomerValue
                }
            },
            set(obj) {
                this.selectedCustomerValue = obj.value
            }
        }
    },
    methods: {
         getCustomer: function(){

            const payload = {
                type: this.customerTypes.find(x => x.value == this.customer.faceType).valueNumber,
                // status: true,
                organizationId: this.customer.organizationId,
                sort: 'id'
            }
            return new Promise((resolve, reject) => {
                this.$crm.post('/customer/find-by-condition', payload).then((res) => {
                    this.customerOptions = res.data.content.map(item => {
                        return {
                            value: item.id,
                            label: item.customerCode + " " + item.fullName
                        }
                    });
                    this.customerOptions.unshift({ value: null, label: this.$t('menuCustomer.all') })
                    resolve(true);
                }).catch((err) => {
                    reject(false);
                    return this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })

        },
        languageData() {
            this.customerData().then(() => {
                if (this.lengthData == 0 && this.$i18n.locale == 'vn') {
                    document.getElementsByClassName('not-data-table vs-table--not-data')[0].textContent = 'Không có dữ liệu'
                }
            });
        },
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        employeeString(id) {
            let employee = this.customerOptions.find(x => x.value === id);
            return (employee && employee.label) || this.$t('menuCustomer.undefined');
        },
        validateForm() {
            return !this.errors.any()
        },
        filter() {
            this.$vs.loading();
            this.customer.start = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.customer.end = new Date(this.dateRange.endDate.setHours(23, 59, 59));
            this.customer.employeeId = this.selectedCustomerValue ? [this.selectedCustomerValue] : null;
            
            this.$crm.post('/time-keeping/all-in-out-org', this.customer).then((response) => {
                this.customerData = response.data.map(t => {
                    t.deviceType = t.deviceType.toUpperCase();
                    t.urlImage = authConfig.processorDomain + "storage/" + t.imageUrl;
                    t.checkinTime = moment(t.createdAt).format("DD/MM/YYYY HH:mm:ss");
                    if (!t.employeeId) t.employeeId = 'N/A';
                    if (!t.fullName) t.fullName = 'N/A';
                    return {
                        ...t,
                        created_at_display: moment(t.createdDate).format("DD/MM/YYYY HH:mm:ss"),
                    }
                })
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style lang="scss">
.customer-table .ag-theme-material .ag-ltr .ag-cell {
    line-height: 100px;
}
</style>
